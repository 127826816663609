<template>
  <container-page>
    <div v-if="!showForm" class="w-100">
      <div class="d-flex align-items-center" v-if="loading">
        <loader class="m-5"></loader>
        <h2 class="m-0">Chargement...</h2>
      </div>
      <left-bar :clients="clients" class v-if="!loading"></left-bar>
    </div>
    <bloc-simple class="flex-grow-1" v-if="showForm">
      <router-view></router-view>
    </bloc-simple>
  </container-page>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ContainerPage from "@/components/containers/ContainerPage.vue";
import BlocSimple from "@/components/containers/ContainerBloc.vue";
import LeftBar from "@/components/clients/LeftBar.vue";
import Loader from "@/components/bases/Loader.vue";

export default {
  components: {
    ContainerPage,
    BlocSimple,
    LeftBar,
    Loader
  },
  data() {
    return {
      showForm: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      client: "clients/client",
      clients: "clients/clients"
    }),
    color: function() {
      return this.$store.getters["colors/colors"].color2;
    },
    textColor: function() {
      if (this.$store.getters["colors/darkMode"]) {
        return "text-white";
      }
      return "text-dark";
    }
  },
  methods: {
    ...mapActions({
      getClients: "clients/getClients"
    })
  },
  mounted() {
    this.loading = true;
    this.getClients().finally(() => (this.loading = false));
    let id = this.$route.params.id | 0;
    if (id > 0) {
      this.showForm = true;
    } else {
      this.showForm = false;
    }
  },
  watch: {
    $route: function() {
      let id = this.$route.params.id | 0;
      if (id > 0) {
        this.showForm = true;
      } else {
        this.showForm = false;
      }
    }
  }
};
</script>
<style lang="css">
/*
.form-client {
  position: absolute;
  bottom: 0;
  left: 1rem;
  width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  z-index: 1050;
  overflow: auto;
}
*/
</style>