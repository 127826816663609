<template>
  <router-link
    :to="{ name: 'crm_client', params: { id: data.id } }"
    class="d-flex flex-row hover-item p-2 mb-2 m-md-2 rounded cursor-pointer shadow-sm"
    :class="hoverClass"
    :active-class="activeClass"
    tag="div"
    @click="$emit('itemSelected')"
  >
    <div class="d-flex d-flex align-items-center w-100">
      <img src="/images/captain.png" style="width: 2.5rem;" class="mr-3" alt />
      <div class="w-100">
        <div
          class="text-truncate"
          style="width: 100%;"
          v-if="data.client_nom && data.client_prenom"
        >
          <strong>{{ data.client_nom }} {{ data.client_prenom }}</strong>
        </div>
        <div class="text-truncate text-primary" style="width: 100%;" v-else>
          <strong>Nom et prénom à saisir</strong>
        </div>
        <div style="line-height: 1rem;">
          <div
            class="text-truncate text-muted"
            style="max-width: 200px;"
            v-if="data.client_comptes"
          >
            <small>{{ data.client_comptes.description }}</small>
          </div>
          <div
            class="text-truncate text-muted"
            style="max-width: 200px;"
            v-else
          >
            <small>Compte inconnu</small>
          </div>
          <div class="d-flex align-items-center justify-content-between w-100">
            <small>
              <span class="text-muted">
                Crée
                {{ $moment(data.system_insert_time).locale("fr").fromNow() }}
              </span>
            </small>
            <div class="ml-auto d-flex text-primary">
              <div @click.stop="sendEmail(data.client_mail1)">
                <BaseIcon
                  class="mb-1 mr-2"
                  name="mail"
                  width="15"
                  height="15"
                ></BaseIcon>
              </div>
              <div @click.stop="call(data.client_tel1)">
                <BaseIcon
                  class="mb-1"
                  name="phone"
                  width="15"
                  height="15"
                ></BaseIcon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center"></div>
  </router-link>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";
export default {
  components: {
    BaseIcon,
  },
  props: {
    data: {
      Type: Object,
      required: false,
    },
  },
  methods: {
    sendEmail(email) {
      window.location = "mailto:" + email;
    },
    call(num) {
      window.location = "tel:" + num;
    },
  },
  computed: {
    activeClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "text-danger";
      } else {
        return "text-warning";
      }
    },
    hoverClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    },
  },
};
</script>
<style lang="css">
.fill-primary {
  fill: #007bff;
}
.fill-warning {
  fill: #ffc107;
}
.fill-success {
  fill: #28a745;
}
.fill-danger {
  fill: #dc3545;
}
</style>
