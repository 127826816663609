<template>
  <div class="w-100">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="m-0">Clients</h2>
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter un client'"
        text="Ajouter"
        @click="newClient"
        :loading="loading"
        icon="plus"
      ></BaseButton>
    </div>
    <div class="p-2 p-md-4 w-100 rounded" v-background-3>
      <input-search
        class="mt-3"
        text="Rechercher..."
        v-model="searchText"
        :showResults="false"
        @reset="searchText = ''"
      ></input-search>
      <div class="mt-3 d-none d-md-block">
        <div class="pl-1">
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('')"
            :class="{ active: filterLetter === '' }"
          >Tous</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('A')"
            :class="{ active: filterLetter === 'A' }"
          >A</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('B')"
            :class="{ active: filterLetter === 'B' }"
          >B</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('C')"
            :class="{ active: filterLetter === 'C' }"
          >C</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('D')"
            :class="{ active: filterLetter === 'D' }"
          >D</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('E')"
            :class="{ active: filterLetter === 'E' }"
          >E</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('F')"
            :class="{ active: filterLetter === 'F' }"
          >F</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('G')"
            :class="{ active: filterLetter === 'G' }"
          >G</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('H')"
            :class="{ active: filterLetter === 'H' }"
          >H</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('I')"
            :class="{ active: filterLetter === 'I' }"
          >I</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('J')"
            :class="{ active: filterLetter === 'J' }"
          >J</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('K')"
            :class="{ active: filterLetter === 'K' }"
          >K</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('L')"
            :class="{ active: filterLetter === 'L' }"
          >L</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('M')"
            :class="{ active: filterLetter === 'M' }"
          >M</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('N')"
            :class="{ active: filterLetter === 'N' }"
          >N</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('O')"
            :class="{ active: filterLetter === 'O' }"
          >O</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('P')"
            :class="{ active: filterLetter === 'P' }"
          >P</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('Q')"
            :class="{ active: filterLetter === 'Q' }"
          >Q</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('R')"
            :class="{ active: filterLetter === 'R' }"
          >R</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('S')"
            :class="{ active: filterLetter === 'S' }"
          >S</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('T')"
            :class="{ active: filterLetter === 'T' }"
          >T</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('U')"
            :class="{ active: filterLetter === 'U' }"
          >U</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('V')"
            :class="{ active: filterLetter === 'V' }"
          >V</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('W')"
            :class="{ active: filterLetter === 'W' }"
          >W</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('X')"
            :class="{ active: filterLetter === 'X' }"
          >X</span>
          <span
            class="mr-1 mr-md-2 cursor-pointer hover-danger"
            @click="filter('Y')"
            :class="{ active: filterLetter === 'Y' }"
          >Y</span>
          <span
            class="cursor-pointer hover-danger"
            @click="filter('Z')"
            :class="{ active: filterLetter === 'Z' }"
          >Z</span>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex flex-column flex-md-row flex-md-wrap mt-2">
        <div v-for="client in clientsNew" :key="'new_' + client.id" class="width-item">
          <left-bar-item :data="client" :style="{ backgroundColor: color3 }"></left-bar-item>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row flex-md-wrap mt-2">
        <div v-for="client in clientsFiltered" :key="'old_' + client.id" class="width-item">
          <left-bar-item :data="client" :style="{ backgroundColor: color3 }"></left-bar-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import leftBarItem from "@/components/clients/LeftBarItem.vue";
import InputSearch from "@/components/bases/InputSearch.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: { leftBarItem, InputSearch, BaseButton },
  data() {
    return {
      loading: false,
      filterLetter: "",
      searchText: ""
    };
  },
  props: {
    clients: {
      Type: Object
    }
  },
  methods: {
    filter: function(letter) {
      this.filterLetter = letter;
    },
    newClient: function() {
      this.loading = true;
      this.$store
        .dispatch("clients/create", { client_compte_id: null })
        .then(client => console.log(client))
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    clientsNew: function() {
      let rep = this.clients.filter(client => {
        if (!client.client_nom) {
          return true;
        }
      });
      return rep;
    },
    clientsFiltered: function() {
      if (this.searchText) {
        let rep = this.clients.filter(client => {
          return (
            client.client_nom
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            client.client_prenom
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            client.client_comptes.description
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          );
        });

        return rep;
      }

      if (this.filterLetter) {
        let rep = this.clients.filter(client => {
          if (
            client.client_nom !== null &&
            client.client_nom.charAt(0) === this.filterLetter
          ) {
            return true;
          } else {
            return false;
          }
        });
        return rep;
      }

      let rep = this.clients.filter(client => {
        if (client.client_nom !== null) {
          return true;
        } else {
          return false;
        }
      });
      return rep;
    },
    color2: function() {
      return this.$store.getters["colors/colors"].color2;
    },
    color3: function() {
      return this.$store.getters["colors/colors"].color3;
    }
  }
};
</script>
<style lang="css">
.hover-danger {
  color: #007bff;
}
.hover-danger:hover {
  color: #dc3545;
}
.hover-danger.active {
  color: #dc3545;
}
.long-list {
  height: auto;
  overflow: auto;
}

.width-bar {
  max-width: 100%;
  overflow-y: auto;
}

/**
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
 */

@media (max-width: 576px) {
  .width-item {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .width-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 992px) {
  .width-item {
    width: calc(100% / 3);
  }
}
@media (min-width: 1200px) {
  .width-item {
    width: calc(100% / 4);
  }
}
@media (min-width: 1500px) {
  .width-item {
    width: calc(100% / 5);
  }
}
</style>
